import Splide from '@splidejs/splide';

const productlistings = document.querySelectorAll('.product-listing');
let isotopeItems = [];
productlistings.forEach((listing) => {
  isotopeItems = isotopeItems.concat(Array.from(listing.querySelectorAll('.isotope-item')));
});
const productListItems = isotopeItems;
let splide = undefined;

const bindEvents = () => {
  const isDealerBuyersGuideListing = document.getElementById('buyersGuide') != null;
  productListItems.forEach((item) => {
    const colorSwatches = item.querySelector('.plp-color-swatches')?.firstElementChild;
    const titleSection = item.querySelector('.product-info-section');
    const swatchNameElem = item.querySelector('.selected-swatch-name');
    const slider = item.querySelector('.slider-wrapper');
    const slides = parseInt(item.querySelector('.product-list-item')?.dataset.images);
    const arrows = item.querySelector('.splide__arrows');

    //This has to be done here instead of cshtml to affect only PLP,
    //since swatch name should visible in mobile on PDP
    swatchNameElem?.classList.add('d-none');
    swatchNameElem?.classList.add('d-lg-block');

    if (colorSwatches && !isDealerBuyersGuideListing) {
      item.addEventListener('mouseenter', () => {
        colorSwatches?.classList.remove('d-lg-none');
        titleSection?.classList.add('d-lg-none');
      });
      item.addEventListener('mouseleave', () => {
        colorSwatches?.classList.add('d-lg-none');
        titleSection?.classList.remove('d-lg-none');
      });
    }

    if (arrows && slides > 1) {
      item.addEventListener('mouseenter', () => {
        arrows?.classList.remove('d-lg-none');
      });
      item.addEventListener('mouseleave', () => {
        arrows?.classList.add('d-lg-none');
      });
    }

    if (slider) {
      splide = new Splide(slider, {
        type: 'slide',
        start: 0,
        arrows: slides > 1,
        pagination: false,
        drag: slides > 1,
        clones: 0,
      });
      splide.mount();
    }
  });
};

const init = () => {
  if (_THULEDATA.IsEditing) {
    return;
  }
  bindEvents();
};

window.addEventListener('changePLPLayout', function () {
  init();
});

document.addEventListener('DOMContentLoaded', function () {
  if (productlistings == null) return;
  init();
});
